import React, { ReactChild, ReactNodeArray } from 'react'
import { FormattedMessage } from 'react-intl'

interface IInfoItem {
  first: string
  second: any
  variant?: string
  l?: number
  r?: number
  className?: string
  flagСolon?: boolean
  flagSize?: boolean
}

const InfoItem: React.FC<IInfoItem> = ({ variant, l, r, first, second, flagСolon, flagSize }) => {
  const className = variant ?? 'default'

  const classNameBg = (item: any) => {
    if (second === 'BUY') {
      return 'bg-buy badge badge-pill'
    } else if (second === 'Buy') {
      return 'bg-buy badge badge-pill'
    } else if (second === 'Sell') {
      return 'bg-sell badge badge-pill'
    } else if (second === 'SELL') {
      return 'bg-sell badge badge-pill'
    }
  }

  return (
    <div className={`row info-item ${className}`}>
      <div className={l ? `col-${l}` : 'col-6'}>
        <span className="mr-4 nowrap" style={flagСolon ? { fontSize: '13px', fontWeight: 400, color: '#7094B3' } : {}}>
          {first ? <FormattedMessage id={first}>{(txt: any) => (flagСolon ? `${txt}` : `${txt}:`)}</FormattedMessage> : ''}
        </span>
      </div>
      <div
        className={r ? `col-${r}` : 'col-6'}
        style={
          flagСolon
            ? { fontSize: flagSize ? '13px' : '14px', fontWeight: 600, color: '#7094B3', paddingRight: flagSize ? 0 : 15 }
            : { fontSize: classNameBg(second) ? '12px' : '13px' }
        }
      >
        {typeof second === 'string' ? <span className={classNameBg(second)}>{second}</span> : second}
      </div>
    </div>
  )
}

export default InfoItem
