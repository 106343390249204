import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { buildHTTPGetOptions, checkResponse, processError } from '../../utils/fetch-utils'
import { brokerProfit, buildInfoItems, buildSummaryDeals, idVisible, infoDirectionDeals, infoMs, infoProfitDealsMt4, infoTime, infoTimeNotUtc } from '../../utils/info-item-utils'
import { AppAccordion, AppTable } from '@t4b/core/lib'
import { IRightbar } from './rightbar-types'
import { buildTableData, buildTableHeadersConfigured } from '../../utils/table-utils'
import { aggregationBar } from '../../const/TradingHistoryConst'
import { showModal } from '../../redux/actions/modal-actions'
import { ModalType } from '../modals/modals'
import { buildDealsUrl } from '../../redux/actions/deals-actions'
import { throwErrorMessage } from '../../utils/errors-utils'
import { Action } from '../../redux/actions/actions'

const MT4Deals: React.FC<IRightbar> = ({
  data: {
    Gateway,
    Platform,
    position: { OrderId },
  },
}) => {
  const dispatch = useDispatch()
  const [position, setPosition] = useState<any>({})
  const [sortOptions, setSortOptions] = React.useState({
    field: '',
    by: 'none',
  })

  useEffect(() => {
    const url = new URL('/api/deal', window.location.origin)
    url.searchParams.set('gateway', Gateway)
    url.searchParams.set('platform', Platform)
    url.searchParams.set('id', OrderId)
    fetch(url.toString(), buildHTTPGetOptions())
      .then((response: Response) => checkResponse(response))
      .then((data: any) => setPosition(data))
      .catch((error: Error) => processError(error, dispatch))
  }, [Gateway, OrderId, Platform, dispatch])

  const additionalComponent = (
    <div className="row">
      <div className="col-6">{buildInfoItems(['Mt4AccountName', 'UserGroup', 'OrderType', '', 'TakeProfit'], position, 'mt4dealsBar', 'default', true)}</div>
      <div className="col-6">{buildInfoItems(['ContractSize', 'Digits', 'Comment', '', 'StopLoss'], position, 'mt4dealsBar', 'default', true)}</div>
    </div>
  )

  const tableOpen = (
    <AppTable
      tableHeader={buildTableHeadersConfigured(aggregationBar, sortOptions, setSortOptions, 'aggregationDeals')}
      tableData={buildTableData(
        position?.OpenLpExecutions ?? [],
        aggregationBar,
        () => {},
        () => {},
        false,
        position.Direction !== 'BUY' ? 'SELL' : 'BUY',
      )}
      scrollOptions={{ x: 'scroll', y: 'visible-hidden' }}
    />
  )

  const tableClose = (
    <AppTable
      tableHeader={buildTableHeadersConfigured(aggregationBar, sortOptions, setSortOptions, 'aggregationDeals')}
      tableData={buildTableData(
        position?.CloseLpExecutions ?? [],
        aggregationBar,
        () => {},
        () => {},
        false,
        position.Direction === 'BUY' ? 'SELL' : 'BUY',
      )}
      scrollOptions={{ x: 'scroll', y: 'visible-hidden' }}
    />
  )

  const generalRowOne = buildInfoItems([idVisible('OrderId'), 'UserId', 'Symbol', infoDirectionDeals('Direction')], position, 'mt4dealsBar', 'default', true)
  const generalRowTwo = buildInfoItems(['Volume', 'LotVolume', 'PositionPrice', 'PlatformName'], position, 'mt4dealsBar', 'default', true)

  const profitRowOne = buildInfoItems([infoDirectionDeals('TraderProfit')], position, 'mt4dealsBar', 'default', true)

  const openDealRowOne = buildInfoItems([brokerProfit('OpenTpBrokerProfit')], position, 'mt4dealsBar', 'default', true)
  const openDealRowTwo = buildInfoItems(
    [
      infoProfitDealsMt4('OpenPrice', true, position.Direction !== 'BUY' ? 'SELL' : 'BUY'),
      infoProfitDealsMt4('OpenRequestPrice', true, position.Direction !== 'BUY' ? 'SELL' : 'BUY'),
      'OpenLpExecutionPrice',
      'OpenLpRequestPrice',
      'OpenTpRequestPrice',
    ],
    position,
    'mt4dealsBar',
    'default',
    true,
  )
  const openDealRowTree = buildInfoItems(['OpenLpOrderId', 'OpenExternalId'], position, 'mt4dealsBar', 'default', true)
  const openDealRowFour = buildInfoItems(
    [infoTime('OpenRequestTime'), infoTimeNotUtc('OpenLpExecutionTimestamp'), infoMs('OpenLpExecutionTime'), infoMs('OpenTpExecutionTime'), infoMs('OpenFullExecutionTime')],
    position,
    'mt4dealsBarOpen',
    'default',
    true,
  )
  const openDealRowFive = buildInfoItems(['OpenTpMarkup', 'OpenTotalMarkup', 'OpenLpPriceSlippage', 'OpenPlatformPriceSlippage'], position, 'mt4dealsBar', 'default', true)
  const openDealRowSix = buildInfoItems(['OpenPlacement'], position, 'mt4dealsBar', 'default', true)

  const flagOpenTpBrokerProfit = position.OpenTpBrokerProfit?.includes('USD')

  const showModalDetailsOpen = () => {
    const url = buildDealsUrl({
      Gateway,
      Platform,
      Order: position.Mt4DbOpenOrderId,
      data: position,
      PageName: 'bookSnapshotReport',
    })
    fetch(url.toString(), buildHTTPGetOptions())
      .then((response: Response) => checkResponse(response))
      .then(data => {
        if (data.Errors?.length) {
          throwErrorMessage(data.Errors.join(' '), true)
          return
        } else {
          dispatch({ type: Action.GotReport, data })
          dispatch(
            showModal(ModalType.ModalDetails, {
              headerText: 'ModalDetails',
              params: {
                Gateway,
                Platform,
                Order: position.Mt4DbOpenOrderId,
                data: position,
                PageName: 'reportDownload',
              },
            }),
          )
        }
      })
      .catch((error: Error) => processError(error, dispatch))
  }

  const showModalDetailsClose = () => {
    const url = buildDealsUrl({
      Gateway,
      Platform,
      Order: position.Mt4DbCloseOrderId,
      data: position,
      PageName: 'bookSnapshotReport',
    })
    fetch(url.toString(), buildHTTPGetOptions())
      .then((response: Response) => checkResponse(response))
      .then(data => {
        if (data.Errors?.length) {
          throwErrorMessage(data.Errors.join(' '), true)
          return
        } else {
          dispatch({ type: Action.GotReport, data })
          dispatch(
            showModal(ModalType.ModalDetails, {
              headerText: 'ModalDetails',
              params: {
                Gateway,
                Platform,
                Order: position.Mt4DbCloseOrderId,
                data: position,
                PageName: 'reportDownload',
              },
            }),
          )
        }
      })
      .catch((error: Error) => processError(error, dispatch))
  }

  const openDeal = (
    <>
      <div className="borderDeal">
        <span style={{ color: '#324766', fontWeight: 600, fontSize: 14 }} className="mb-4">
          Open deal
        </span>
        <div className="mt-2">
          {openDealRowOne}
          <div className="row">
            <div className="col-6" style={{ fontSize: 13, fontWeight: 400, color: '#7094B2' }}>
              Total broker profit
            </div>
            <div className="ml-3">
              <span
                style={{ color: position['OpenTpBrokerProfit']?.includes('-') ? '#99423B' : '#30527A', fontSize: 14, fontWeight: 600 }}
              >{`${position['OpenTotalBrokerProfit']} `}</span>
              {flagOpenTpBrokerProfit ? null : (
                <>
                  <span>{'|'}</span>
                  <span
                    style={{ color: position['OpenTotalBrokerProfit']?.includes('-') ? '#99423B' : '#30527A', fontSize: 14, fontWeight: 600 }}
                  >{` ${position['OpenTotalBrokerProfitUsd']}`}</span>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="dealsOrderMin mt-2"></div>
        <div className="mt-2">
          <span className="modalDetailsMT4" onClick={showModalDetailsOpen}>
            Details...
          </span>
          {openDealRowTwo}
        </div>
        <div className="dealsOrderMin mt-2"></div>
        <div className="mt-2">{openDealRowTree}</div>
        <div className="dealsOrderMin mt-2"></div>
        <div className="mt-2">{openDealRowFour}</div>
        <div className="dealsOrderMin mt-2"></div>
        <div className="mt-2">{openDealRowFive}</div>
        <div className="dealsOrderMin mt-2"></div>
        <div className="mt-2">{openDealRowSix}</div>
      </div>
    </>
  )

  const closeDealRowOne = buildInfoItems([brokerProfit('CloseTpBrokerProfit')], position, 'mt4dealsBar', 'default', true)
  const closeDealRowTwo = buildInfoItems(
    [
      infoProfitDealsMt4('ClosePrice', false, position.Direction === 'BUY' ? 'SELL' : 'BUY'),
      infoProfitDealsMt4('CloseRequestPrice', false, position.Direction === 'BUY' ? 'SELL' : 'BUY'),
      'CloseLpExecutionPrice',
      'CloseLpRequestPrice',
      'CloseTpRequestPrice',
    ],
    position,
    'mt4dealsBar',
    'default',
    true,
  )
  const closeDealRowTree = buildInfoItems(['CloseLpOrderId', 'CloseExternalId'], position, 'mt4dealsBar', 'default', true)
  const closeDealRowFour = buildInfoItems(
    [infoTime('CloseRequestTime'), infoTimeNotUtc('CloseLpExecutionTimestamp'), infoMs('CloseLpExecutionTime'), infoMs('CloseTpExecutionTime'), infoMs('CloseFullExecutionTime')],
    position,
    'mt4dealsBarClose',
    'default',
    true,
  )
  const closeDealRowFive = buildInfoItems(['CloseTpMarkup', 'CloseTotalMarkup', 'CloseLpPriceSlippage', 'ClosePlatformPriceSlippage'], position, 'mt4dealsBar', 'default', true)
  const closeDealRowSix = buildInfoItems(['ClosePlacement'], position, 'mt4dealsBar', 'default', true)

  const flagCloseTpBrokerProfit = position.CloseTpBrokerProfit?.includes('USD')
  const closeDeal = (
    <>
      <div className="borderDeal">
        <span style={{ color: '#324766', fontWeight: 600, fontSize: 14 }} className="mb-4">
          Close deal
        </span>
        <div className="mt-2">
          {closeDealRowOne}
          <div className="row">
            <div className="col-6" style={{ fontSize: 13, fontWeight: 400, color: '#7094B2' }}>
              Total broker profit
            </div>
            <div className="ml-3">
              <span
                style={{ color: position['CloseTotalBrokerProfit']?.includes('-') ? '#99423B' : '#30527A', fontSize: 14, fontWeight: 600 }}
              >{`${position['CloseTotalBrokerProfit']} `}</span>
              {flagCloseTpBrokerProfit ? null : (
                <>
                  <span>{'|'}</span>
                  <span
                    style={{ color: position['CloseTotalBrokerProfit']?.includes('-') ? '#99423B' : '#30527A', fontSize: 14, fontWeight: 600 }}
                  >{` ${position['CloseTotalBrokerProfitUsd']}`}</span>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="dealsOrderMin mt-2"></div>
        <div className="mt-2">
          <span className="modalDetailsMT4" onClick={showModalDetailsClose}>
            Details...
          </span>
          {closeDealRowTwo}
        </div>
        <div className="dealsOrderMin mt-2"></div>
        <div className="mt-2">{closeDealRowTree}</div>
        <div className="dealsOrderMin mt-2"></div>
        <div className="mt-2">{closeDealRowFour}</div>
        <div className="dealsOrderMin mt-2"></div>
        <div className="mt-2">{closeDealRowFive}</div>
        <div className="dealsOrderMin mt-2"></div>
        <div className="mt-2">{closeDealRowSix}</div>
      </div>
    </>
  )

  const additionalExecutionTimestampsOpen = buildInfoItems(
    ['OpenPlatformRequestTimestamp', 'OpenLpRequestTimestamp', 'OpenLpExecutionTimestamp', 'OpenLpAnswerTimestamp', 'OpenTpExeucutionTime', 'OpenPlatformExecutionTimestamp'],
    position,
    'mt4dealsBar',
    'additional',
    true,
  )
  const additionalExecutionTimestampsClose = buildInfoItems(
    ['ClosePlatformRequestTimestamp', 'CloseLpRequestTimestamp', 'CloseLpExecutionTimestamp', 'CloseLpAnswerTimestamp', 'CloseTpExeucutionTime', 'ClosePlatformExecutionTimestamp'],
    position,
    'mt4dealsBar',
    'additional',
    true,
  )

  return (
    <>
      <Card>
        <Card.Header
          style={{
            color: 'rgb(141, 171, 196)',
            fontWeight: 500,
            fontSize: '13px',
            textTransform: 'uppercase',
          }}
        >
          <FormattedMessage id="order-detail" />
        </Card.Header>
        <Card.Body>
          <div className="mb-2">
            <span style={{ color: '#324766', fontWeight: 600, fontSize: 14 }}>General</span>
            <section className="row mt-2">
              <div className="col-6">{generalRowOne}</div>
              <div className="col-6">{generalRowTwo}</div>
            </section>
            <div className="dealsOrder mt-2"></div>
          </div>
          <div className="mb-2">
            <span style={{ color: '#324766', fontWeight: 600, fontSize: 14 }}>Profit</span>
            <section className="row mt-2">
              <div className="col-6">
                <div className="row default info-item">
                  <div className="col-6" style={{ fontSize: 13, fontWeight: 400, color: '#7094B2' }}>
                    Total broker profit
                  </div>
                  <div className="col-6">
                    <div
                      className={String(position?.TotalBrokerProfit)?.includes('-') ? 'brokerProfitMinus pl-1 pr-1' : 'brokerProfit pl-1 pr-1'}
                    >{`${position['TotalBrokerProfit']} `}</div>
                    {String(position?.TotalBrokerProfit) === String(position?.TotalBrokerProfitUsd) ? null : (
                      <>
                        <span style={{ height: 21, color: '#30527A', fontSize: 16, fontWeight: 500 }}>{'|'}</span>
                        <div
                          className={String(position?.TotalBrokerProfitUsd)?.includes('-') ? 'brokerProfitMinus pl-1 pr-1' : 'brokerProfit pl-1 pr-1'}
                        >{` ${position['TotalBrokerProfitUsd']}`}</div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-6">{profitRowOne}</div>
            </section>
            <section className="row mt-4">
              <div style={{ margin: 0, padding: 5 }} className="col-6">
                {openDeal}
              </div>
              <div style={{ margin: 0, padding: 5 }} className="col-6">
                {closeDeal}
              </div>
            </section>
          </div>
        </Card.Body>
      </Card>

      <AppAccordion
        item={{
          title: 'Additional',
          item: (
            <>
              <section className="row">
                <div style={{ margin: 0, padding: 5 }} className="col-6">
                  <section className="row mb-2">
                    <div className="borderAdditional borderAdditionalOpen">
                      <span style={{ color: '#324766', fontWeight: 600, fontSize: 14 }} className="mb-4">
                        Open execution timestamps
                      </span>
                      <div className="mt-2">{additionalExecutionTimestampsOpen}</div>
                    </div>
                  </section>
                </div>
                <div style={{ margin: 0, padding: 5 }} className="col-6">
                  <div className="borderAdditional borderAdditionalClose">
                    <span style={{ color: '#324766', fontWeight: 600, fontSize: 14 }} className="mb-4">
                      Close execution timestamps
                    </span>
                    <div className="mt-2">{additionalExecutionTimestampsClose}</div>
                  </div>
                </div>
              </section>
              <div className="dealsOrder mb-4"></div>
              {additionalComponent}
            </>
          ),
        }}
        style={{ fontSize: '13px' }}
        isHidden={false}
      />

      <AppAccordion
        item={{
          title: <FormattedMessage id="mt5.volume-distribution" />,
          item: [
            <div className="mr-2 mt-2 mb-4">{buildSummaryDeals(position.Summary, 'mt4')}</div>,
            <div className="mt-2 mb-4">
              <span style={{ color: '#324766', fontWeight: 600 }} className="mb-4">
                Open deal details
              </span>
              <div style={{ marginTop: 16 }}> {tableOpen}</div>
            </div>,
            <div className="mt-2 mb-4">
              <span style={{ color: '#324766', fontWeight: 600 }} className="mb-4">
                Close deal details
              </span>
              <div style={{ marginTop: 16 }}> {tableClose}</div>
            </div>,
          ],
        }}
        style={{ fontSize: '14px' }}
        isHidden={false}
        render={position?.Summary?.length}
      />
    </>
  )
}

export default MT4Deals
