import React from 'react'
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { injectIntl, WrappedComponentProps } from 'react-intl'

interface ICheckBoxInput extends WrappedComponentProps {
  state: any
  setState: any
  name: string
  label?: string
  isDisabled?: boolean
  className?: string
  tooltip?: string
  tooltipFlag?: boolean
}

const CheckBoxInput: React.FC<ICheckBoxInput> = props => {
  const { state, setState, name, label, isDisabled, className, tooltip, tooltipFlag } = props
  const handleChange = (event: any) => {
    setState({
      ...state,
      [name]: event.target.checked,
    })
  }

  const renderTooltip = (props?: any, tooltip?: string) => (
    <Tooltip id="button-tooltip" {...props}>
      {tooltip}
    </Tooltip>
  )

  return (
    <OverlayTrigger placement="auto" delay={{ show: 500, hide: 400 }} overlay={renderTooltip({}, tooltip)}>
      <Form.Group controlId={`checkboxid-${Math.random()}`} className={className}>
        {label ? (
          <Form.Check type="checkbox" label={props.intl.formatMessage({ id: `${label}` })} onChange={handleChange} checked={state[name]} disabled={!!isDisabled} />
        ) : (
          <Form.Check type="checkbox" onChange={handleChange} checked={state[name]} disabled={!!isDisabled} />
        )}
      </Form.Group>
    </OverlayTrigger>
  )
}

export default injectIntl(CheckBoxInput)
